import { datadogRum } from "@datadog/browser-rum"
import * as Sentry from "@sentry/remix"
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser } from "@remix-run/react"
import { startTransition, StrictMode } from "react"
import { hydrateRoot } from "react-dom/client"

Sentry.init({
  dsn: "https://56c6c3a24b64e6c26fde264662857ae4@o458616.ingest.us.sentry.io/4508045610778624",
  environment: import.meta.env.VITE_APP_ENV as string,

  integrations: [
    // import/namespace ルールは conditional exports で false positive が発生するため、無効化する
    // @see https://github.com/getsentry/sentry-javascript/issues/12706
    // eslint-disable-next-line import/namespace
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],

  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1,
})

datadogRum.init({
  applicationId: "546e02e5-e0a2-4caf-bdfa-625f72068863",
  clientToken: "pubd7707e4efb08bd331f2c86a1a8948028",
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: "datadoghq.com",
  service: "leaner-connect",
  env: import.meta.env.VITE_APP_ENV as string,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: parseInt(import.meta.env.VITE_DATADOG_RUM_SAMPLE_RATE || "0"),
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask",
  enablePrivacyForActionName: true,
  allowedTracingUrls: [
    {
      match:
        (import.meta.env.VITE_API_BASE_URL as string) || "https://api.connect.leaner.jp/",
      propagatorTypes: ["tracecontext"],
    },
  ],
})

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  )
})
